<template>
  <main>
    <b-container class="mb-5">
      <h1>Page Not Found</h1>
      Sorry, we couldn't find the resource you requested.
    </b-container>
  </main>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>
